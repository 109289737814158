



















import {get} from 'lodash'
import {Vue, Component} from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from '../../components/ContentBox.vue';
import Feature from '../../components/Feature.vue';
import FeatureDetails from '../../components/FeatureDetails.vue';

@Component({
  components: {Title, ContentBox, Feature, FeatureDetails}
})
export default class Features extends Vue {

  get features() {
    return get(this, '$store.state.configuration.edited.features', []);
  }

  get details() {
    return get(this, '$store.state.configuration.details');
  }

  create() {
    const feature = {enabled: false, featureName: 'NewFeature'};
    this.features.unshift(feature);
    this.$store.commit('configuration/setDetails', feature);
  }

}
