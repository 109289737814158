





































































import {get, some} from 'lodash';
import {Component, Prop, Vue} from "vue-property-decorator";
import Title from "./Title.vue";
import ContentBox from "./ContentBox.vue";
import VueToggles from 'vue-toggles';
import Multiselect from 'vue-multiselect'

@Component({
  components: { Title, ContentBox, VueToggles, Multiselect }
})
export default class FeatureDetails extends Vue {

  @Prop({ type: Object, default: () => ({}) })
  record: Record<any, any>;

  get viableAudiences() {
    const audiences = get(this, '$store.state.configuration.edited.audiences', []);
    const { enableFor = [], disableFor = [] } = this.record;
    const exceptions = [...enableFor, ...disableFor];
    return audiences.filter(audience => !some(exceptions, audience));
  }

  toggle() {
    this.record.enabled = !this.record.enabled;
  }

  remove() {
    this.$store.commit('configuration/removeFeature', this.record);
  }

}
