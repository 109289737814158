
































import {get} from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from "@/components/ContentBox.vue";
import VueToggles from 'vue-toggles';

@Component({
  components: { Title, ContentBox, VueToggles }
})
export default class Feature extends Vue {

  @Prop({ type: Object, default: () => ({}) })
  record: Record<any, any>;

  get enabledFor() {
    const audiences = get(this, 'record.enableFor', []);
    return audiences.map(e => e.audienceName);
  }

  get disabledFor() {
    const audiences = get(this, 'record.disableFor', []);
    return audiences.map(e => e.audienceName);
  }

  toggle({ value }) {
    this.record.enabled = value;
  }

  select() {
    this.$store.commit('configuration/setDetails', this.record);
  }

  remove() {
    this.$store.commit('configuration/removeFeature', this.record);
  }
}

